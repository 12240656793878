import React,{useEffect} from "react";
import Text from "../../components/data-display/text";
import AppleStore from "../../images/apple-appstore.svg";
import { Check } from "react-feather";
import data from "./about-us.json";
import influence from "./our-influence.json";
import SEO from "../../components/seo/seo";
import makeStyles from "@material-ui/core/styles/makeStyles";
import '../../components/gallery-components/index.css';
import App from '../../components/gallery-components/App';
import { Container, Grid, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { OpenRoute } from "../../components/app-routes/app-routes";
import AppStore from "../../images/google_playstore.svg";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    margin: "0 auto",
    marginTop: "90px",
    marginBottom: "75px"
  },
  badges: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly"
  },
  bigSpacing: {
    height: "30px"
  },
  smallSpacing: {
    height: "15px"
  },
  cover: {
    objectFit: 'cover',
  }
}));
const AboutUs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
          <SEO title="About us" pathname="/about-us"/>
    <div style={{ background: "white" }}>
    <div
      style={{
        position: "relative",
        paddingTop: "150px",
        paddingBottom: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      data-scroll={true}
    >
      <Container
        maxWidth={"xl"}
        style={{ padding: "0 12%" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Grid item xs={12} sm={12} style={{}}>
            <div style={{ paddingBottom: "30px", textAlign: "center", verticalAlign: "middle", marginBottom: !xsDown ? "60px" : "" }}>
              <Text
                variant={"h1"}
                black
             
              >
                About Us
              </Text>

             
            </div>
          </Grid>
          <ul
            style={{ listStyle: "none", paddingLeft: "0px" }}
          >
            {data.map((c) => {
              return (
                <li key={c.title}>
                  <>
                    {mdDown ? (
                      <Grid container>
                        {c.image && <Grid item md={12} lg={12} xl={12}>
                          <img
                            loading={"lazy"} 
                            className={classes.cover}
                            src={c.image}
                            alt={c.title}
                            style={{
                              width: "85%",
                              height:!xsDown ? "90%":"100%",
                            }}
                          />

                        </Grid>}
                        {!c.image && !xsDown && <div style={{ height: "20px" }}></div>}
                        <Grid item md={12} lg={12} xl={12}>
                          {c.title != "" && <div
                          >

                            <Text
                              variant={"h3"}
                              black
                              style={{marginTop:smDown?"20px":"0px", marginBottom:smDown?"20px":"0px"}}
                            >
                              {c.title}{" "}
                            </Text>

                          </div>}
                          <Text
                            regular
                            variant={"h6"}
                            style={{ maxWidth: "100%", }}
                          >
                            {c.description}
                            <div style={{ height: "25px" }} />
                            {c.description1}
                          </Text>
                          {c.apps && <div style={{ display:"flex", justifyContent:"flex-start" , marginTop:"20px" }} className={classes.badges}>
                            <a href={"https://apps.apple.com/us/app/sydetasker/id1502353211"} rel="noopener noreferrer" target={"_blank"}>
                              <img   loading="lazy" alt="Download on the App Store" src={AppleStore} style={{   }} />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=ai.unicode.sydetasker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" rel="noopener noreferrer" target={"_blank"}>
                              <img   loading="lazy" alt="Get it on Google Play" src={AppStore} style={{  marginLeft: "20px" }} />
                            </a>
                          </div>}
                        </Grid>
                        {!c.description&& <>
                              <Text variant={"body1"} style={{ textAlign: "center" }}>
                                Outsourcing tasks on Sydetasker has a positive impact on everyone in the surrounding community!
                              </Text>
                              <div className={classes.smallSpacing} />
                              {(influence ?? []).map((str) => {
                                return (
                                  <div style={{ height: "auto", padding: "14px 0px" }}>
                                    <Check size={18} style={{ color: "#4caf50", verticalAlign: "middle", marginRight: "8px" }} />
                                    <Text variant={"body1"} black component={"span"}>
                                      {str.title}
                                    </Text>
                                    <Text variant={"h6"} regular component={"p"} style={{ paddingLeft: "45px" }}>
                                      {str.description}
                                    </Text>
                                  </div>
                                )
                              })}
                              </>}
                      </Grid>
                    ) : (
                      <Grid container>
                        {!c.right &&
                          <>
                            <Grid xs={6} sm={6} lg={6}>
                              <img
                                loading={"lazy"} 
                                className={classes.cover}
                                src={c.image}
                                alt={c.title}
                                style={{
                                  width: "80%",
                                  height:"93%"
                                
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} sm={6} lg={6} style={{ paddingLeft: "30px",  }}>
                              {c.title && <div >
                                <Text
                                  variant={"h3"}
                                  black
                                >
                                  {c.title}
                                </Text>
                                <div style={{height:"26px"}}/>
                              </div>}
                              <Text
                                regular
                                variant={"h6"}
                                style={{ maxWidth: "600px", marginTop: xsDown ? "16px" : "", }}
                              >
                                {c.description}

                                {c.description1 &&
                                  <>
                                    <div style={{ height: "9px", }} />
                                    {c.description1}
                                  </>
                                }
                                 {c.apps &&
                                  <div  style={{ display:"flex", justifyContent:"flex-start" , marginTop:"20px" }} className={classes.badges}>
                                    <a href={"https://apps.apple.com/us/app/sydetasker/id1502353211"} rel="noopener noreferrer" target={"_blank"}>
                                      <img   loading="lazy" alt="Download on the App Store" src={AppleStore} style={{  }} />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=ai.unicode.sydetasker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" rel="noopener noreferrer" target={"_blank"}>
                                      <img   loading="lazy" alt="Get it on Google Play" src={AppStore} style={{ marginLeft:"20px" }} />
                                    </a>
                                  </div>}

                              </Text>
                              <div className={classes.smallSpacing} />
                             {!c.description&& <>
                              <Text variant={"body1"} style={{ textAlign: "center" }}>
                                Outsourcing tasks on Sydetasker has a positive impact on everyone in the surrounding community!
                              </Text>
                              <div className={classes.smallSpacing} />
                              {(influence ?? []).map((str) => {
                                return (
                                  <div style={{ height: "auto", padding: "14px 0px" }}>
                                    <Check size={18} style={{ color: "#4caf50", verticalAlign: "middle", marginRight: "8px" }} />
                                    <Text variant={"body1"} black component={"span"}>
                                      {str.title}
                                    </Text>
                                    <Text variant={"h6"} regular component={"p"} style={{ paddingLeft: "45px" }}>
                                      {str.description}
                                    </Text>
                                  </div>
                                )
                              })}
                              </>}
                            </Grid>
                          </>
                        }
                        {c.right &&
                          <>
                            <Grid item xs={6} sm={6} style={{}}>
                              {c.title && <div style={{ paddingBottom: "0px", marginTop: xsDown ? "16px" : "0px" }}>
                                <Text
                                  variant={"h3"}
                                  black
                                >
                                  {c.title}
                                </Text>
                              </div>}
                              <Text
                                regular
                                variant={"h6"}
                                style={{ maxWidth: "570px", marginTop: xsDown ? "16px" : "30px", }}
                              >
                                {c.description}
                                <div style={{ height: "6px" }} />
                                {c.description1}
                                {c.apps &&
                                  <div  style={{ display:"flex", justifyContent:"flex-start" , marginTop:"20px" }} className={classes.badges}>
                                    <a href={"https://apps.apple.com/us/app/sydetasker/id1502353211"} rel="noopener noreferrer" target={"_blank"}>
                                      <img  loading="lazy" alt="Download on the App Store" src={AppleStore} style={{ }} />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=ai.unicode.sydetasker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" rel="noopener noreferrer" target={"_blank"}>
                                      <img loading="lazy" alt="Get it on Google Play" src={AppStore} style={{  marginLeft:"20px"}} />
                                    </a>
                                  </div>}

                              </Text>
                            </Grid>
                            <Grid xs={6} sm={6}>
                              <img
                               loading={"lazy"} 
                                className={classes.cover}
                                src={c.image}
                                alt={c.title}
                                style={{
                                  width: "90%",
                                  height:"90%"
                                }}
                              />
                            </Grid>
                          </>
                        }
                      </Grid>
                    )}
                  </>
    
              {c.title != "" && c.title != "Our Positive Influence " && c.title != "Our Beginning"&& !xsDown && <div
                    style={{
                      height: "200px",
                      width: "100%"
                    }}
                  />}

              {c.title != "" && c.title == "Our Beginning"&& !xsDown && <div
                    style={{
                      height: "100px",
                      width: "100%"
                    }}
                  />}
                  {xsDown && <div
                    style={{
                      height: "20px",
                      width: "100%"
                    }}
                  />}
                </li>
              );
            })}
          </ul>
        </div>
        {/* <div style={{ height: "64px" }} /> */}
      </Container>
    </div>
    <div style={{marginTop:"100px"}}>
    <App />
    </div>
    <div style={{ height: "64px" }}></div>
  </div>
  </>
  );
};

export default AboutUs;